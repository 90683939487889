<template>
  <div>
    <div class="top-menu-visible">
    <a
      class="app-menu-back"
      @click="
        $router.go(-1);
        isOpen = false;
      "
      ><i class="fas fa-chevron-left"></i
    ></a></div>
    <div class="guest-view"></div>
    <div class="container login-wrapper">
      <div class="logo-container">
        <img :src="require('../assets/app_icon/Logo_BM_pion.png')"/>
      </div>
      <div class="guest-input-container container">
        <ValidationObserver ref="observer" tag="form" >
          <form  @submit.prevent="submit">
            <ValidationProvider :rules="{required: true, email: true}" v-slot="{errors, classes}">
                <input type="email" class="form-input w-100 control" :class="classes"
                  placeholder="Podaj swój email" v-model="email"/>
                <span class="form-error-info text-center">{{ errors[0] }}</span>
            </ValidationProvider>
              <button type="submit"  class="btn-white-full w-100 mt-3">Przypomnij hasło</button>
          </form>
        </ValidationObserver>
                <div class="text-danger mt-5 text-center">{{errorMessage}}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
// import router from '../router'
extend('required', {
  ...required,
  message: 'Pole jest obowiązkowe'
})

extend('email', {
  ...email,
  message: 'Niepoprawny format adresu email'
})

export default {
  name: 'LoginEmail',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data () {
    return {
      email: '',
      getResponse: '',
      errorMessage: ''
    }
  },
  methods: {
    async submit () {
      const valid = await this.$refs.observer.validate()
      if (valid === true) {
        this.remind()
      }
    },
    remind () {
      const getData = `email=${this.email}`
      this.$https('/request-password-reset', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
        if (response.data.response === 'Wystąpił błąd') {
          this.errorMessage = 'Adres e-mail nie został zarejestrowany w Bookme'
        } else {
          this.errorMessage = 'Link do zmiany hasła został wysłany na podany adres e-mail'
        }
      })
        .catch(error => {
          console.log('', error)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
  .guest-input-container {
    position: absolute;
    top: 50%;
    color: #fff;

    input {
      border: none;
      border-bottom: 1px solid #fff;
      color: #fff;
      text-align: center;
      &::placeholder {
        color: #fff;
      }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
      border-bottom: 1px solid #fff;
      -webkit-text-fill-color: #fff;
      box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0.5) inset;
      -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0.5) inset;
      transition: background-color 5000s ease-in-out 0s;
    }
  }
  }
  .guest-link-container {
    position: absolute;
    font-size: 14px;
    bottom: 5%;

        a {
      color: #fff;
      text-decoration: underline;
      text-align: center;
    }
  }

  .form-error-info {
    font-size: 11px;
  }
  .control {
      &.invalid {
    border-bottom: 1px solid red;
  }

  &.valid {
    border-color: #fff;
  }
}
</style>
